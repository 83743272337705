import { useState, Fragment, React, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import { sendGetRequest, useGetRequest, sendPostRequest } from "../../Client";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, Table, Modal } from "flowbite-react";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import Header from "../../Components/Header";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { Dialog, Transition } from "@headlessui/react";
import { MultiSelect } from "react-multi-select-component";

export default function Compliance() {
  const queryClient = useQueryClient();

  const [ongoingInitial, setOngoingInitial] = useState("Ongoing");

  const [searchChecked, setSearchChecked] = useState(0);
  const [searchApproved, setSearchApproved] = useState(0);
  const [searchAdvisor, setSearchAdvisor] = useState("");
  const [searchClient, setSearchClient] = useState("");
  const [searchFromDate, setSearchFromDate] = useState("");
  const [searchToDate, setSearchToDate] = useState("");

  const [fetchedData, setFetchedData] = useState([]);
  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(false);

  const [submittedID, setSubmittedID] = useState();

  // loading submitted
  const { data: submitted, isLoading } = useQuery({
    queryFn: () => sendGetRequest("submitted", {}),
    queryKey: ["submitted"],
  });

  useEffect(() => {
    setFetchedData(submitted);
  }, [submitted]);

  const { status: statusCompanies, data: dataCompanies } = useGetRequest(
    "companies",
    {}
  );

  function getCurrencySymbol(currency) {
    if (currency == 1) {
      return "£";
    } else if (currency == 2) {
      return "$";
    } else if (currency == 3) {
      return "€";
    } else if (currency == 4) {
      return "CZK";
    } else {
      return "£";
    }
  }

  const fetchPosts = async () => {
    setLoading(true);

    const requestBody = {
      company: selectedFormatted,
      fromDate: searchFromDate,
      toDate: searchToDate,
      provider: selectedProviderFormatted,
      client: searchClient,
      advisor: searchAdvisor,
      checked: searchChecked,
      approved: searchApproved,
    };

    const res = await sendPostRequest("submitted/search", requestBody);

    setFetchedData(res);

    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearched(true);
    fetchPosts();
  };

  // updating

  // confirmation notifications
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const updatedNotyf = () => {
    notyf.success("Record updated");
  };

  const updateSubmitted = async (path, body) => {
    const res = await sendPostRequest(path, body);
    updatedNotyf();
  };

  const { mutateAsync: doUpdateMutation } = useMutation({
    mutationFn: (data) => {
      return updateSubmitted(data.path, { submittedID, ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["submitted"] });
    },
  });

  const setChecked = (submittedID, value) => {
    doUpdateMutation({
      path: "submitted/checked",
      body: {
        submittedID: submittedID,
        checked: value,
      },
    });
  };

  const updateApprovedSubmitted = async (path, body) => {
    const res = await sendPostRequest(path, body);
    updatedNotyf();
  };

  const { mutateAsync: doUpdateApprovedMutation } = useMutation({
    mutationFn: (data) => {
      return updateApprovedSubmitted(data.path, { submittedID, ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["submitted"] });
    },
  });

  const setApproved = (submittedID, value) => {
    doUpdateApprovedMutation({
      path: "submitted/approved",
      body: {
        submittedID: submittedID,
        checked: value,
      },
    });
  };

  const [selected, setSelected] = useState([]);
  const [selectedFormatted, setSelectedFormatted] = useState([]);

  useEffect(() => {
    if (selected.length > 0) {
      setSelectedFormatted(selected.map((item) => item.value));
    }
  }, [selected]);

  const [selectedProvider, setSelectedProvider] = useState([]);
  const [selectedProviderFormatted, setSelectedProviderFormatted] = useState(
    []
  );

  useEffect(() => {
    if (selectedProvider.length > 0) {
      setSelectedProviderFormatted(selectedProvider.map((item) => item.value));
    }
  }, [selectedProvider]);

  if (!isLoading && statusCompanies === "fetched") {
    return (
      <>
        <Header />

        <section className="bg-gray-100 dark:bg-gray-900 py-3 sm:py-5">
          <div className="px-4 mx-auto max-w-screen-2xl lg:px-12 mb-80">
            <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
              <div className="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
                <div className="flex items-center flex-1 space-x-4">
                  <h2 className="p-6 text-xl font-bold text-gray-900 dark:text-white">
                    Compliance{" "}
                  </h2>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="border px-2 py-2 mt-5">
                  <div className="w-full flex flex-row mt-4 justify-center align-end">
                    {/* <div className="w-1/6">
                      <label
                        htmlFor="provider"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Provider{" "}
                      </label>
                      <div className="mt-2">
                        <MultiSelect
                          options={[
                            { label: "AJ Bell", value: "AJ Bell" },
                            {
                              label: "Ardan International",
                              value: "Ardan International",
                            },
                            { label: "Ascentric", value: "Ascentric" },

                            {
                              label: "Brooks MacDonald",
                              value: "Brooks MacDonald",
                            },
                            {
                              label: "Capital Platform",
                              value: "Capital Platform",
                            },
                            { label: "Friends Life", value: "Friends Life" },

                            {
                              label: "Friends Provident",
                              value: "Friends Provident",
                            },

                            {
                              label: "Generali/Utmost WW",
                              value: "Generali/Utmost WW",
                            },
                            { label: "Momentum", value: "Momentum" },
                            { label: "Morningstar", value: "Morningstar" },
                            { label: "Novia", value: "Novia" },
                            { label: "Novia Global", value: "Novia Global" },
                            { label: "Utmost", value: "Utmost" },
                          ]}
                          value={selectedProvider}
                          onChange={(selectedProvider) => {
                            // selectedFunction(selected);
                            setSelectedProvider(selectedProvider);
                          }}
                          labelledBy="Select"
                        />
                      </div>
                    </div> */}

                    {/* <div className="w-1/6">
                      <label
                        htmlFor="company"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Company{" "}
                      </label>
                      <div className="mt-2 ml-2">
                        <MultiSelect
                          options={dataCompanies.companies.map((companies) => ({
                            label: companies.companyName,
                            value: companies.companyID,
                          }))}
                          value={selected}
                          onChange={(selected) => {
                            // selectedFunction(selected);
                            setSelected(selected);
                          }}
                          labelledBy="Select"
                        />
                      </div>
                    </div> */}

                    <div className="w-1/6">
                      <div className="mb-5 mx-2">
                        <label
                          htmlFor="client"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Client Name / Reference
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            id="client"
                            onChange={(e) => setSearchClient(e.target.value)}
                            name="client"
                            className=" border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-1/6">
                      <div className="mb-5 mx-2">
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="fromDate"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            From Date
                          </label>
                          <div className="mt-2">
                            <input
                              type="date"
                              name="fromDate"
                              id="fromDate"
                              onChange={(e) =>
                                setSearchFromDate(e.target.value)
                              }
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/6">
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="toDate"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          To Date
                        </label>
                        <div className="mt-2">
                          <input
                            type="date"
                            name="toDate"
                            id="toDate"
                            onChange={(e) => setSearchToDate(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-1/8 p-2 ml-4">
                      <label
                        for="searchChecked"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Checked
                      </label>
                      <input
                        type="checkbox"
                        id="searchChecked"
                        checked={searchChecked}
                        value={1}
                        onChange={() => setSearchChecked(!searchChecked)}
                        className="align-center h-4"
                      />
                    </div>

                    <div className="w-1/8 p-2">
                      <label
                        for="searchApproved"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Approved
                      </label>
                      <input
                        type="checkbox"
                        id="searchApproved"
                        checked={searchApproved}
                        value={1}
                        onChange={() => setSearchApproved(!searchApproved)}
                        className="align-center h-4"
                      />
                    </div>

                    <button
                      type="submit"
                      className="w-20 ml-8 mt-8 h-10 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </form>

              {loading ? (
                <div className="inline-block min-w-full py-2 flex justify-center sm:px-6 lg:px-8">
                  <ReactLoading type={"spin"} color="#5046e5" />
                </div>
              ) : (
                <div className="overflow-x-auto p-6">
                  <div className="flex flex-row mb-2">
                    <div
                      onClick={() => setOngoingInitial("Ongoing")}
                      className="bg-slate-100 p-4 hover:bg-slate-200 hover:cursor-pointer rounded-md w-24 text-center"
                    >
                      Ongoing
                    </div>
                    <div
                      onClick={() => setOngoingInitial("Initial")}
                      className="ml-2 bg-slate-100 p-4 hover:bg-slate-200 hover:cursor-pointer rounded-md w-24 text-center"
                    >
                      Initial
                    </div>
                  </div>
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-4 py-3">
                          Record ID
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Start Date
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Company
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Client
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Client Ref
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Product
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Provider
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Owner
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Invested Value
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Checked
                        </th>
                        <th scope="col" className="px-4 py-3">
                          Approved
                        </th>
                      </tr>
                    </thead>
                    {fetchedData?.submitted?.length > 0 &&
                    ongoingInitial === "Ongoing" ? (
                      <tbody>
                        {fetchedData?.submitted.map((item) => (
                          <tr className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
                            <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                              {item.recordID}
                            </td>

                            <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                              {item.startDate}
                            </td>

                            <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                              {item.companyName}
                            </td>

                            <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                              {item.clientSurname}, {item.clientForename}
                            </td>

                            <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                              {item.clientRef}
                            </td>

                            <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                              {item.product}
                            </td>

                            <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                              {item.productProvider}
                            </td>

                            <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                              {item.surname}, {item.firstName}
                            </td>

                            <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                              {getCurrencySymbol(item.currency) +
                                item.investedValue}
                            </td>

                            <td className="w-32 px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              {item.checked === 0 ? (
                                <button
                                  type="button"
                                  onClick={() =>
                                    setChecked(item.submittedID, 1)
                                  }
                                  className="relative -ml-px inline-flex items-center rounded-l-md rounded-r-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-400 focus:z-10"
                                >
                                  Checked
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() =>
                                    setChecked(item.submittedID, 0)
                                  }
                                  className="relative -ml-px inline-flex items-center rounded-l-md rounded-r-md bg-green-400 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-green-300 hover:bg-green-400 focus:z-10"
                                >
                                  Checked
                                </button>
                              )}
                            </td>
                            <td className="w-32 px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              {item.approved === 0 ? (
                                <button
                                  type="button"
                                  onClick={() =>
                                    setApproved(item.submittedID, 1)
                                  }
                                  className="relative -ml-px inline-flex items-center rounded-l-md rounded-r-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-400 focus:z-10"
                                >
                                  Approved
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() =>
                                    setApproved(item.submittedID, 0)
                                  }
                                  className="relative -ml-px inline-flex items-center rounded-l-md rounded-r-md bg-green-400 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-green-300 hover:bg-green-400 focus:z-10"
                                >
                                  Approved
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody className="divide-y divide-gray-200">
                        <tr>
                          <td
                            colSpan="16"
                            className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-red-500"
                          >
                            No results found
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}
